import React from "react";
import style from "./style.module.scss";

const Footer: React.FC = () => {
  return (
    <footer className={style.footer}>
      <div>主办单位：上海大学　国家语言文字推广基地</div>
      <div>运行维护：上海大学中国手语及聋人研究中心</div>
      <div>上海大学中国手语及聋人研究中心 版权所有</div>
    </footer>
  );
};

export default Footer;
