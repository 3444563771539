import React from "react";
import Carousel from "../../components/Carousel";
import Dynasty from "../../components/Dynasty";
import Rank from "../../components/Rank";
import style from "./style.module.scss";

const Main: React.FC = () => {
  return (
    <div className={style.home}>
      <div className={style.top}>
        <Carousel />
        <Rank />
      </div>
      <Dynasty />
    </div>
  );
};

export default Main;
