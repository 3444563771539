import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import data from "../../static/data.json";
import { DATA_URL } from "../../utils/const";
import { useHistory } from "react-router-dom";

const Carousel: React.FC = () => {
  const history = useHistory();
  const poems = data.list.slice(0, 4);
  const [currentPoem, setCurrentPoem] = useState(poems[0]);

  useEffect(() => {
    const duration = 3000;
    const timer = setInterval(() => {
      const currentPoemIndex = poems.findIndex(
        (item) => item.id === currentPoem.id
      );
      const nextPoemIndex = (currentPoemIndex + 1) % poems.length;
      setCurrentPoem(poems[nextPoemIndex]);
    }, duration);
    return () => {
      clearInterval(timer);
    };
  }, [currentPoem.id, poems]);

  return (
    <div className={style.carousel}>
      <div className={style.left}>
        <div className={style.thumbnails}>
          {poems.map((item) => {
            return (
              <div key={item.id} className={style.preview}>
                <img
                  className={currentPoem.id === item.id ? style.active : ""}
                  src={
                    DATA_URL +
                    "poem_videos/" +
                    encodeURIComponent(`${item.id}${item.title}.jpg`)
                  }
                  alt={item.title}
                  onClick={() => {
                    setCurrentPoem(item);
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div
        className={style.right}
        onClick={() => {
          history.push(`/detail/${currentPoem.id}`);
        }}
      >
        <img
          src={
            DATA_URL +
            "poem_videos/" +
            encodeURIComponent(`${currentPoem.id}${currentPoem.title}.jpg`)
          }
          alt={currentPoem.title}
        />

        <div className={style.footer}>
          <div className={style.poem}>
            <div>《{currentPoem.title}》</div>
            <div>{currentPoem.author}</div>
          </div>
          <div className={style.performer}>
            <div>
              <span>朗诵：</span>
              <span>{currentPoem.reciter}</span>
            </div>
            <div>
              <span>专家解读：</span>
              <span>{currentPoem.expositor}</span>
            </div>
            <div>
              <span>手语诵读：</span>
              <span>{currentPoem.performer}</span>
            </div>
            <div>
              <span>手语释义：</span>
              <span>{currentPoem.interpreter}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
