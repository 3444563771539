import React from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Detail from "./views/Detail";
import "./App.scss";

const App: React.FC = () => {
  return (
    <Router >
      <div className="App">
        <Header />
        <main>
          <TransitionGroup>
            <CSSTransition classNames="fade" timeout={300}>
              <Switch>
                <Route exact path="/" children={<Home />} />
                <Route path="/detail/:id" children={<Detail />} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
