import React from "react";
import rankImage from "./rank.png";
import style from "./style.module.scss";
import data from "../../static/data.json";
import { Link } from "react-router-dom";

const Rank: React.FC = () => {
  const rankList = data.list.slice(0, 10);

  return (
    <div className={style.rank}>
      <img src={rankImage} alt="rank" />
      <ol>
        {rankList.map((item) => {
          return (
            <li key={item.id}>
              <Link className={style.item} to={`/detail/${item.id}`}>
                <div className={style.title}>{item.title}</div>
                <div className={style.author}>{item.author}</div>
              </Link>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default Rank;
