export const DATA_URL = "https://ccsl-1252692614.file.myqcloud.com/";
export interface Poem {
  id: string;
  poster: string;
  video: string;
  poem: string;
  title: string;
  author: string;
  dynasty: string;
  comment: string;
  translation: string;
  reciter: string;
  expositor: string;
  performer: string;
  interpreter: string;
  animationTime: number;
  expositTime: number;
  reciteTime: number;
  interpretTime: number;
}
