import React, { useEffect, useRef, useState } from "react";
import Plyr from "plyr";
import "plyr/dist/plyr.css";
import { useParams } from "react-router-dom";
import data from "../../static/data.json";
import style from "./style.module.scss";
import chapterOptions from "./options";
import { DATA_URL } from "../../utils/const";
import { useHistory } from "react-router-dom";

const Detail: React.FC = () => {
  const player = useRef<Plyr>();
  const params = useParams<{ id: string }>();
  const [chapter, setChapter] = useState("");
  const history = useHistory();

  const poem = data.list.find((item) => item.id === params.id);

  useEffect(() => {
    player.current = new Plyr("#player");
  }, []);

  const handleClick = (chapter: string) => {
    setChapter(chapter);
    const timeKey = chapter + "Time";
    const jumpTime = (poem as any)[timeKey];
    if (player.current) {
      player.current.currentTime = jumpTime;
      player.current.play();
    }
  };

  return (
    <div className={style.detail}>
      <div className={style.breadcrumb}>
        <span
          className={style.option}
          onClick={() => {
            history.push("/");
          }}
        >
          首页
        </span>
        &gt;
        <span
          className={style.option}
          onClick={() => {
            history.push(
              `/?dynasty=${encodeURIComponent(poem?.dynasty || "")}`
            );
          }}
        >
          {poem?.dynasty}
        </span>
        &gt;
        <span className={style.option}>{poem?.title}</span>
      </div>
      <div className={style.player}>
        <video
          id="player"
          playsInline
          controls
          controlsList="nodownload"
          data-poster={
            DATA_URL +
            "poem_videos/" +
            encodeURIComponent(`${poem?.id}${poem?.title}.jpg`)
          }
        >
          <source
            src={
              DATA_URL +
              "poem_videos/" +
              encodeURIComponent(`${poem?.id}${poem?.title}.mp4`)
            }
            type="video/mp4"
          />
        </video>
      </div>

      <div className={style.chapters}>
        {chapterOptions.map((item) => {
          return (
            <img
              key={item.value}
              src={item.value === chapter ? item.selected : item.normal}
              alt={item.value}
              onClick={() => {
                handleClick(item.value);
              }}
            />
          );
        })}
      </div>
      <div className={style.content}>
        <div className={style.left}>
          <div className={style.title}>{poem?.title}</div>
          <div className={style.author}>{poem?.author}</div>
          <div className={style.poem}>
            {poem?.poem.split("\n").map((item, index) => {
              return <div key={index}>{item}</div>;
            })}
          </div>
          <div className={style.subtitle}>「注释」</div>
          <div>
            {poem?.comment.split("\n").map((item, index) => {
              return <div key={index}>{item}</div>;
            })}
          </div>
          <div className={style.subtitle}>「译文」</div>
          <div className={style.translation}>{poem?.translation}</div>
        </div>
        <div className={style.divider}></div>
        <div className={style.right}>
          <div className={style.role}>「朗诵」</div>
          <div>{poem?.reciter}</div>
          <div className={style.role}>「专家解读」</div>
          <div>{poem?.expositor}</div>
          <div className={style.role}>「手语诵读」</div>
          <div>{poem?.performer}</div>
          <div className={style.role}>「手语释义」</div>
          <div>{poem?.interpreter}</div>
        </div>
      </div>
    </div>
  );
};

export default Detail;
