import animationNormal from "./正常-诗意动画.png";
import animationSelected from "./选中-诗意动画.png";
import expositNormal from "./正常-专家解读.png";
import expositSelected from "./选中-专家解读.png";
import reciteNormal from "./正常-手语诵读.png";
import reciteSelected from "./选中-手语诵读.png";
import interpretNormal from "./正常-手语释义.png";
import interpretSelected from "./选中-手语释义.png";

const options = [
  {
    value: "animation",
    normal: animationNormal,
    selected: animationSelected,
  },
  {
    value: "exposit",
    normal: expositNormal,
    selected: expositSelected,
  },
  {
    value: "recite",
    normal: reciteNormal,
    selected: reciteSelected,
  },
  {
    value: "interpret",
    normal: interpretNormal,
    selected: interpretSelected,
  },
];

export default options;
