import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dynastyTitleImage from "./dynasty.png";
import dynastyOptions, { IDynastyOption } from "./options";
import data from "../../static/data.json";
import { Poem } from "../../utils/const";
import style from "./style.module.scss";
import { useQuery } from "../../utils/hooks";

const Dynasty: React.FC = () => {
  const [dynasty, setDynasty] = useState("");
  const [poems, setPoems] = useState<Array<Poem>>([]);
  const query = useQuery();

  const handleClick = (item: IDynastyOption) => {
    setDynasty(item.value);
  };

  useEffect(() => {
    setPoems(data.list.filter((item) => item.dynasty === dynasty));
  }, [dynasty]);

  useEffect(() => {
    setDynasty(query.get("dynasty") || "");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={style.dynasty}>
      <img className={style.title} src={dynastyTitleImage} alt="dynasty" />
      <div className={style.options}>
        {dynastyOptions.map((item) => {
          return dynasty === item.value ? (
            <img key={item.value} src={item.selected} alt={item.value} />
          ) : (
            <img
              key={item.value}
              src={item.normal}
              alt={item.value}
              onClick={() => {
                handleClick(item);
              }}
            />
          );
        })}
      </div>
      <div className={style.list}>
        {dynasty &&
          poems.map((item) => {
            return (
              <Link
                key={item.id}
                to={`/detail/${item.id}`}
                className={style.listItem}
              >
                《{item.title}》{item.author}
              </Link>
            );
          })}
      </div>
    </div>
  );
};

export default Dynasty;
