import qinHanNormal from "./正常-先秦两汉.png";
import qinHanSelected from "./选中-先秦两汉.png";
import weiJinNormal from "./正常-魏晋南北朝.png";
import weiJinSelected from "./选中-魏晋南北朝.png";
import suiTangNormal from "./正常-隋唐五代.png";
import suiTangSelected from "./选中-隋唐五代.png";
import songJinYuanNormal from "./正常-宋金元.png";
import songJinYuanSelected from "./选中-宋金元.png";
import mingQingNormal from "./正常-明清.png";
import mingQingSelected from "./选中-明清.png";

const options: Array<IDynastyOption> = [
  {
    value: "先秦两汉",
    normal: qinHanNormal,
    selected: qinHanSelected,
  },
  {
    value: "魏晋南北朝",
    normal: weiJinNormal,
    selected: weiJinSelected,
  },
  {
    value: "隋唐五代",
    normal: suiTangNormal,
    selected: suiTangSelected,
  },
  {
    value: "宋金元",
    normal: songJinYuanNormal,
    selected: songJinYuanSelected,
  },
  {
    value: "明清",
    normal: mingQingNormal,
    selected: mingQingSelected,
  },
];

export interface IDynastyOption {
  value: string;
  normal: string;
  selected: string;
}

export default options;
